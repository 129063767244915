import { useState, useEffect } from 'react';
import { FileOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { Link, Outlet, useLocation, matchRoutes } from 'react-router-dom';

import router from './router';

const { Header, Content, Sider } = Layout;


function App(){
  const [collapsed, setCollapsed] = useState(false);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['/']);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState(['/']);
  const [openKeys, setOpenKeys] = useState(['/']);
  const [isInit, setIsInit] = useState(false);
  const location = useLocation();
  useEffect(() => {
    console.log("useEffect:", location.pathname);
    const routes = matchRoutes(router, location.pathname);
    var pathArr = [];
    if (routes !== null ) {
      for (let route of routes) {
        let path = route.route.path;
        if (path) {
          pathArr.push(path);
        }
      }
      
    }
    if (pathArr.length > 0) {
      if (!(pathArr[0] === '/' && pathArr.length === 1)) {
        pathArr = pathArr.filter(function(item) {    return item !== "/"});
        pathArr = [pathArr[pathArr.length - 1 ]]
        let ps = pathArr[0].split('/');
        if (ps.length > 2) {
          pathArr.push("/" + ps[1]);
        }
      }
      
      setDefaultSelectedKeys(pathArr);
      setDefaultOpenKeys(pathArr);
      setOpenKeys(pathArr);
      setIsInit(true);
    }
  }, [location.pathname])

  const onOpenChange = (currentOpenKeys) => {
    const currentLength = currentOpenKeys.length;
    const openLength = openKeys.length;
    if (currentLength > 1 && currentLength - openLength === 1) {
      setOpenKeys([currentOpenKeys[currentOpenKeys.length - 1]])
      return
    }
    
    var pathArr = currentOpenKeys;
    if (pathArr.length > 0) {
      if (!(pathArr[0] === '/' && pathArr.length === 1)) {
        pathArr = pathArr.filter(function(item) {    return item !== "/"});
        pathArr = [pathArr[pathArr.length - 1 ]]
        let ps = pathArr[0].split('/');
        if (ps.length > 2) {
          pathArr.push("/" + ps[1]);
        }
      }
    }

    setOpenKeys(pathArr);
  }

  const onClick = (item, key, keyPath, domEvent) => {
    console.log("onClick:", item, key, keyPath, domEvent);
  }

  if (!isInit) {
    return null;
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={ value => setCollapsed(value) }>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys} openKeys={openKeys} onClick={onClick} onOpenChange={onOpenChange} mode="inline" > 
          <Menu.Item icon={<FileOutlined />} key='/'>
            <Link to='/'>欢迎</Link>
          </Menu.Item>
          <Menu.SubMenu icon={<UserOutlined />} key='/users' title="用户">
            <Menu.Item key="/users/" >
            <Link to='/users/'>基础信息查询</Link>
            </Menu.Item>
            <Menu.Item key="/users/detailQuery" >
              <Link to='/users/detailQuery'>详细信息查询</Link>
            </Menu.Item>
            <Menu.Item key="/users/blackList" >
              <Link to='/users/blackList'>黑名单</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        />
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;