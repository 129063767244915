import { Space, Table, Input, Form } from 'antd';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { IsLogin, ClearToken } from '../utils';
import {NetUserBasicQuery, NetUserExtUpdate} from '../http';
const { Search } = Input;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};


const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
function BasicQuery() {
  let navigate = useNavigate();
  useEffect(() => {
    if (!IsLogin()){
      navigate('/login');
    }
  });

  const defaultColumns = [
    {title: '玩家UID', dataIndex: 'show_id', key: 'show_id'},
    {title: '昵称', dataIndex: 'name', key: 'name'},
    {title: '等级', dataIndex: 'level', key: 'level'},
    {title: '当前体力', dataIndex: 'energy', key: 'energy'},
    {title: '当前贝壳余额', dataIndex: 'shells', key: 'shells'},
    {title: '当前珍珠余额', dataIndex: 'pearls', key: 'pearls'},
    {title: '鱼缸数量', dataIndex: 'tank_count', key: 'tank_count'},
    {title: '注册时间', dataIndex: 'create_at', key: 'create_at'}, 
    {title: '上次登录时间', dataIndex: 'last_login_time', key: 'last_login_time'},
    {title: '上次离线时间', dataIndex: 'last_offline_time', key: 'last_offline_time'},
    {title: '是否认证邮箱', dataIndex: 'is_verified', key: 'is_verified'},
    {title: '国家', dataIndex:  'country', key: 'country'},
    {title: '累计消耗珍珠数', dataIndex: 'total_consume_pearl', key: 'total_consume_pearl'},
    {title: '当前版本号', dataIndex: 'version', key: 'version'},
    {title: '系统（安卓/IOS)', dataIndex: 'system', key: 'system'},
    {title: '绑定邮箱', dataIndex: 'Email', key: 'Email'},
    {title: '微信昵称', dataIndex: 'wxnickname', key: 'wxnickname', editable: true},
    {title: '微信号', dataIndex: 'wxid', key: 'wxid', editable: true},
    {title: 'QQ号', dataIndex: 'qq', key: 'qq', editable: true},
    {title: '真实姓名', dataIndex: 'real_name', key: 'real_name', editable: true},
    {title: '备注', dataIndex: 'remarks', key: 'remarks', editable: true},
  ];
  const [dataSource, setDataSource] = useState([]);
  // const [queryUserId, setQueryUserId] = useState("");
  // const [queryUserName, setQueryUserName] = useState("");
  const [loading, setLoading] = useState(false);

  const requestData = (queryTyep, value) => {
  //  2697428335@qq.com
    // axios.get(`/api/user/${queryTyep}/${value}?token=${GetToken()}`)
    NetUserBasicQuery(queryTyep, value)
    .then(res => {
      if (res.data.code === -10) {
        ClearToken()
        navigate('/login');
        return;
      }
      console.log(res.data.data);
      setDataSource(res.data.data);
    })
    .catch(e => {
      console.log(e);
    }
    );
  }


  const onSearchUserID = (value, event) => {
    console.log("onSearchUserID:", value);
    setLoading(true);
    requestData("id", value);
    setLoading(false);
  }
  const onSearchUserName = (value, event) => {
    console.log("onSearchUserName:", value);
    setLoading(true);
    requestData("name", value);
    setLoading(false);
  }
  const onSearchUserEmail = (value, event) => {
    console.log("onSearchUserEmail:", value);
    setLoading(true);
    requestData("email", value);
    setLoading(false);
  }
  // const handleDelete = (key) => {
  //   const newData = dataSource.filter((item) => item.key !== key);
  //   setDataSource(newData);
  // };

  const handleSave = (row) => {
    let changeDataKey = null;
    let changeDataValue = null;
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    for (var i of defaultColumns) {
      if (i.hasOwnProperty("editable")) {
        if ((
            item.hasOwnProperty(i.key) && item[i.key] !== row[i.key]
          ) || (
            !item.hasOwnProperty(i.key) && row.hasOwnProperty(i.key)
          )){
            changeDataKey = i.key;
            changeDataValue = row[i.key];
            break
        }
      }
    }

    console.log("changeData:", changeDataKey, changeDataValue);
    newData.splice(index, 1, { ...item, ...row });
    setLoading(true);
    NetUserExtUpdate(item.email, changeDataKey, changeDataValue)
      .then(res => {
        if (res.code === 0) {
          setDataSource(newData);
        } else {
          alert(res.msg);
        }
      })
      .catch(err => {
        console.log("err:", err);
      });
    setLoading(false);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  
  return (
    <>
      <Space
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        {/* <Input placeholder="请输入玩家ID" />
        <Input placeholder="请输入玩家昵称" />

        <Button onClick={ requestData }>查询</Button>
        <Button >编辑</Button> */}

        <Search
          placeholder="请输入玩家ID"
          allowClear
          enterButton="查询"
          size="large"
          loading={loading}
          onSearch={onSearchUserID}
        />

        <Search
          placeholder="请输入玩家昵称"
          allowClear
          enterButton="查询"
          size="large"
          loading={loading}
          onSearch={onSearchUserName}
        />
        <Search
          placeholder="请输入玩家邮箱"
          allowClear
          enterButton="查询"
          size="large"
          loading={loading}
          onSearch={onSearchUserEmail}
        />
      </Space>
      <Table 
        components={components}
        rowClassName={() => 'editable-row'}
        bordered 
        columns={columns} 
        dataSource={dataSource} 
        loading={loading}
      />
    </>
  );
}

export default BasicQuery;