import { Space, Table, Input, Select } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { fishFilterSource, tankFilterSource, propFilterSource } from '../config';
import { IsLogin, ClearToken } from '../utils';
import {NetUserDetailQuery} from "../http";

const { Search } = Input;
const { Option } = Select;

const myFilters = {
  fish: fishFilterSource,
  tank: tankFilterSource,
  prop: propFilterSource,
};

function DetailQuery() {
  let navigate = useNavigate();
  useEffect(() => {
    if (!IsLogin()){
      navigate('/login');
    }
  });
  const [dataSource, setDataSource] = useState([]);
  // const [queryUserId, setQueryUserId] = useState("");
  // const [queryUserName, setQueryUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [selectType, setSelectType] = useState("fish");

  const columns = [
    {title: '物品ID', dataIndex: 'item', key: 'item',
      filters: myFilters[selectType].map(item => ({ text: item.item, value: item.item })),
      filteredValue: filteredInfo.item || null,
      onFilter: (value, record) => record.item === value,
      ellipsis: true,
    },
    {title: '名称', dataIndex: 'name', key: 'name',
      filters: myFilters[selectType].map(item => ({ text: item.name, value: item.name })),
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name === value,
      ellipsis: true,
    },
    {title: '等级', dataIndex: 'level', key: 'level'},
    {title: '数量', dataIndex: 'count', key: 'count'},
    {title: '种族', dataIndex: 'family', key: 'family',
      filters: selectType === "fish" ? Array.from(new Set(myFilters[selectType].map(item => item.family ))).map(item => ({text: item, value: item})): null,
      filteredValue: filteredInfo.family || null,
      onFilter: (value, record) => {
        return record.family === value
      },
      ellipsis: true,
    },
    {title: '获取时间', dataIndex: 'create_at', key: 'create_at'},
    {title: '获取途径', dataIndex: 'get', key: 'get'},
  ];
  

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
  };

  // const clearFilters = () => {
  //   setFilteredInfo({});
  // };

  // const clearAll = () => {
  //   setFilteredInfo({});
  //   setSortedInfo({});
  // };


  const requestData = (queryTyep, value) => {
    //  2697428335@qq.com
      // axios.get(`/api/article/${queryTyep}/${value}?article_type=${selectType}?token=${GetToken()}`)
      NetUserDetailQuery(selectType, queryTyep, value)
      .then(res => {
        if (res.data.code === -10) {
          ClearToken()
          navigate('/login');
          return;
        }
        console.log(res.data.data);
        setDataSource(res.data.data);
      })
      .catch(e => {
        console.log(e);
      }
      );
    }

  const onSearchUserID = (value, event) => {
    console.log("onSearchUserID:", value);
    setLoading(true);
    requestData("id", value);
    setLoading(false);
  }

  const onSearchUserName = (value, event) => {
    console.log("onSearchUserName:", value);
    setLoading(true);
    requestData("name", value);
    setLoading(false);
  }

  const handleChangeSelect = (value) => {
    console.log("handleChangeSelect:", value);
    setSelectType(value);
  }

  return (
    <>
      <Space
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <Select
          defaultValue="fish"
          style={{
            width: 120,
          }}
          onChange={handleChangeSelect}
        >
          <Option value="fish">鱼</Option>
          <Option value="prop">精华</Option>
          <Option value="tank">鱼缸</Option>
        </Select>

        <Search
          placeholder="请输入玩家ID"
          allowClear
          enterButton="查询"
          size="large"
          loading={loading}
          onSearch={onSearchUserID}
        />

        <Search
          placeholder="请输入玩家昵称"
          allowClear
          enterButton="查询"
          size="large"
          loading={loading}
          onSearch={onSearchUserName}
        />
      </Space>
      <Table 
        columns={columns} 
        dataSource={dataSource} 
        loading={loading}
        onChange={handleChange}
      />
    </>
  );
}

export default DetailQuery;