import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { IsLogin } from '../utils';

function BlackList() {
  let navigate = useNavigate();
  useEffect(() => {
    if (!IsLogin()){
      navigate('/login');
    }
  });
  return (
    <div>
      <h1>BlackList</h1>
      <p>
        欢迎使用黑名单
      </p>
    </div>
  );
}

export default BlackList;