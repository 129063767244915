import { useNavigate } from 'react-router-dom'
import { IsLogin } from '../utils';
import { useEffect } from 'react';


function Dashboard() {
  let navigate = useNavigate();
  console.log(`BasicQuery IsLogin() = ${IsLogin()}`)
  useEffect(() => {
    if (!IsLogin()){
      navigate('/login');
      return;
    }
  });
  
  return (
    <div>
      <h1>
        欢迎使用Let`s Fish Admin
      </h1>
    </div>
  );
}

export default Dashboard;