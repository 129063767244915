import axios from 'axios';
import { GetToken, hex_sha1 } from './utils';

axios.defaults.headers.post['Content-Type'] = 'application/json';
function NetLogin(email, password) {
  return axios.post(`/api/login`, {
    email,
    password: hex_sha1(password),
  });
}

function NetUserBasicQuery(queryTyep, value) {
  return axios.get(`/api/user/${queryTyep}/${value}?token=${GetToken()}`);
}

function NetUserDetailQuery(articleType, queryType, value) {
    return axios.get(`/api/article/${queryType}/${value}?article_type=${articleType}?token=${GetToken()}`)
}

function NetUserExtUpdate(email, key, value) {
  return axios.post(`/api/userext?token=${GetToken()}`, {
    email, key, value
  })
}

export { NetLogin, NetUserBasicQuery, NetUserDetailQuery, NetUserExtUpdate };