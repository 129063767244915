import Dashboard from '../pages/dashboard';
import BasicQuery from '../pages/basicQuery';
import DetailQuery from '../pages/detailQuery';
import BlackList from '../pages/blackList';
import Login from '../pages/Login';

import AppLayout from '../AppLayout'

const router = [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            {
                index: true,
                element: <Dashboard />
            },
            {
                path: '/users/',
                children: [
                    {
                        index: true,
                        element: <BasicQuery />
                    },
                    {
                        path: '/users/detailQuery',
                        element: <DetailQuery />
                    },
                    {
                        path: '/users/blackList',
                        element: <BlackList />
                    }
                ]
            },
            {
                path: '/fish/',
                children: [
                    {
                        index: true,
                        element: <BasicQuery />
                    },
                    {
                        path: '/fish/detailQuery',
                        element: <DetailQuery />
                    },
                    {
                        path: '/fish/blackList',
                        element: <BlackList />
                    }
                ]
            }
        ]
    },
    {
        path: '/login',
        element: <Login />
    }

]

export default router;