// const HTTP_SERVER = "https://test.admin.letsfish.me"

const tankFilterSource = [
	{'item': 't_0001_1', 'name': '灰小破缸', 'star': 1, 'capacity': 6},
	{'item': 't_0002_1', 'name': '绿小圆缸', 'star': 1, 'capacity': 6},
	{'item': 't_0002_2', 'name': '蓝小圆缸', 'star': 1, 'capacity': 7},
	{'item': 't_0002_3', 'name': '黄小圆缸', 'star': 1, 'capacity': 8},
	{'item': 't_0002_4', 'name': '红小圆缸', 'star': 1, 'capacity': 9},
	{'item': 't_0002_5', 'name': '紫小圆缸', 'star': 1, 'capacity': 10},
	{'item': 't_0003_1', 'name': '绿三角缸', 'star': 1, 'capacity': 7},
	{'item': 't_0003_2', 'name': '蓝三角缸', 'star': 1, 'capacity': 8},
	{'item': 't_0003_3', 'name': '黄三角缸', 'star': 1, 'capacity': 9},
	{'item': 't_0003_4', 'name': '红三角缸', 'star': 1, 'capacity': 10},
	{'item': 't_0003_5', 'name': '紫三角缸', 'star': 1, 'capacity': 11},
	{'item': 't_0004_1', 'name': '绿宽底小圆缸', 'star': 1, 'capacity': 8},
	{'item': 't_0004_2', 'name': '蓝宽底小圆缸', 'star': 1, 'capacity': 9},
	{'item': 't_0004_3', 'name': '黄宽底小圆缸', 'star': 1, 'capacity': 10},
	{'item': 't_0004_4', 'name': '红宽底小圆缸', 'star': 1, 'capacity': 11},
	{'item': 't_0004_5', 'name': '紫宽底小圆缸', 'star': 1, 'capacity': 12},
	{'item': 't_1001_1', 'name': '绿椭圆缸', 'star': 2, 'capacity': 8},
	{'item': 't_1001_2', 'name': '蓝椭圆缸', 'star': 2, 'capacity': 9},
	{'item': 't_1001_3', 'name': '黄椭圆缸', 'star': 2, 'capacity': 10},
	{'item': 't_1001_4', 'name': '红椭圆缸', 'star': 2, 'capacity': 11},
	{'item': 't_1001_5', 'name': '紫椭圆缸', 'star': 2, 'capacity': 12},
	{'item': 't_1002_1', 'name': '绿中圆缸', 'star': 2, 'capacity': 9},
	{'item': 't_1002_2', 'name': '蓝中圆缸', 'star': 2, 'capacity': 10},
	{'item': 't_1002_3', 'name': '黄中圆缸', 'star': 2, 'capacity': 11},
	{'item': 't_1002_4', 'name': '红中圆缸', 'star': 2, 'capacity': 12},
	{'item': 't_1002_5', 'name': '紫中圆缸', 'star': 2, 'capacity': 13},
	{'item': 't_2001_1', 'name': '绿大圆缸', 'star': 3, 'capacity': 9},
	{'item': 't_2001_2', 'name': '蓝大圆缸', 'star': 3, 'capacity': 10},
	{'item': 't_2001_3', 'name': '黄大圆缸', 'star': 3, 'capacity': 11},
	{'item': 't_2001_4', 'name': '红大圆缸', 'star': 3, 'capacity': 12},
	{'item': 't_2001_5', 'name': '紫大圆缸', 'star': 3, 'capacity': 13},
	{'item': 't_2002_1', 'name': '绿喇叭花缸', 'star': 3, 'capacity': 9},
	{'item': 't_2002_2', 'name': '蓝喇叭花缸', 'star': 3, 'capacity': 11},
	{'item': 't_2002_3', 'name': '黄喇叭花缸', 'star': 3, 'capacity': 12},
	{'item': 't_2002_4', 'name': '红喇叭花缸', 'star': 3, 'capacity': 13},
	{'item': 't_2002_5', 'name': '粉喇叭花缸', 'star': 3, 'capacity': 16},
	{'item': 't_9001_1', 'name': '小南瓜', 'star': 2, 'capacity': 5},
	{'item': 't_9002_1', 'name': '大南瓜', 'star': 2, 'capacity': 10},
	{'item': 't_9003_1', 'name': '冰无极', 'star': 4, 'capacity': 16},
	{'item': 't_9003_2', 'name': '白无极', 'star': 4, 'capacity': 6}
]


const propFilterSource = [
	{'item': 'p_0001_1', 'name': '體力', 'category': 'POW', 'value': 3600},
	{'item': 'p_0002_1', 'name': '一星绿精华', 'category': 'ESS', 'value': [1,'GR',256]},
	{'item': 'p_0002_2', 'name': '一星蓝精华', 'category': 'ESS', 'value': [1,'BL',128]},
	{'item': 'p_0002_3', 'name': '一星黄精华', 'category': 'ESS', 'value': [1,'YE',64]},
	{'item': 'p_0002_4', 'name': '一星红精华', 'category': 'ESS', 'value': [1,'RE',32]},
	{'item': 'p_0002_5', 'name': '一星紫精华', 'category': 'ESS', 'value': [1,'PU',16]},
	{'item': 'p_0002_6', 'name': '一星幻彩精华', 'category': 'ESS', 'value': [1,'X',8]},
	{'item': 'p_0003_1', 'name': '二星绿精华', 'category': 'ESS', 'value': [2,'GR',256]},
	{'item': 'p_0003_2', 'name': '二星蓝精华', 'category': 'ESS', 'value': [2,'BL',128]},
	{'item': 'p_0003_3', 'name': '二星黄精华', 'category': 'ESS', 'value': [2,'YE',64]},
	{'item': 'p_0003_4', 'name': '二星红精华', 'category': 'ESS', 'value': [2,'RE',32]},
	{'item': 'p_0003_5', 'name': '二星紫精华', 'category': 'ESS', 'value': [2,'PU',16]},
	{'item': 'p_0003_6', 'name': '二星幻彩精华', 'category': 'ESS', 'value': [2,'X',8]},
	{'item': 'p_0004_1', 'name': '三星绿精华', 'category': 'ESS', 'value': [3,'GR',256]},
	{'item': 'p_0004_2', 'name': '三星蓝精华', 'category': 'ESS', 'value': [3,'BL',128]},
	{'item': 'p_0004_3', 'name': '三星黄精华', 'category': 'ESS', 'value': [3,'YE',64]},
	{'item': 'p_0004_4', 'name': '三星红精华', 'category': 'ESS', 'value': [3,'RE',32]},
	{'item': 'p_0004_5', 'name': '三星紫精华', 'category': 'ESS', 'value': [3,'PU',16]},
	{'item': 'p_0004_6', 'name': '三星幻彩精华', 'category': 'ESS', 'value': [3,'X',8]},
	{'item': 'p_0005_1', 'name': '四星绿精华', 'category': 'ESS', 'value': [4,'GR',256]},
	{'item': 'p_0005_2', 'name': '四星蓝精华', 'category': 'ESS', 'value': [4,'BL',128]},
	{'item': 'p_0005_3', 'name': '四星黄精华', 'category': 'ESS', 'value': [4,'YE',64]},
	{'item': 'p_0005_4', 'name': '四星红精华', 'category': 'ESS', 'value': [4,'RE',32]},
	{'item': 'p_0005_5', 'name': '四星紫精华', 'category': 'ESS', 'value': [4,'PU',16]},
	{'item': 'p_0005_6', 'name': '四星幻彩精华', 'category': 'ESS', 'value': [4,'X',8]},
	{'item': 'p_0101_1', 'name': 'VIP月卡', 'category': 'VIP', 'value': 3600*24*30},
	{'item': 'p_0101_2', 'name': 'VIP年卡', 'category': 'VIP', 'value': 3600*24*365},
	{'item': 'p_0101_3', 'name': 'VIP周卡', 'category': 'VIP', 'value': 3600*24*7},
	{'item': 'p_0102_1', 'name': '交易月卡', 'category': 'EXC', 'value': 3600*24*30}
]

const fishFilterSource = [
	{'item': 'f_0001_1', 'name': 'f00011', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0001_2', 'name': 'f00012', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0001_3', 'name': 'f00013', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0001_4', 'name': 'f00014', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0001_5', 'name': 'f00015', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0001_6', 'name': 'f00016', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0001_7', 'name': 'f00017', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0001_8', 'name': 'f00018', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0001_9', 'name': 'f00019', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_0002_1', 'name': 'f00021', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0002_2', 'name': 'f00022', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0002_3', 'name': 'f00023', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0002_4', 'name': 'f00024', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0002_5', 'name': 'f00025', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0002_6', 'name': 'f00026', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0002_7', 'name': 'f00027', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0002_8', 'name': 'f00028', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0002_9', 'name': 'f00029', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_0003_1', 'name': 'f00031', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0003_2', 'name': 'f00032', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0003_3', 'name': 'f00033', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0003_4', 'name': 'f00034', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0003_5', 'name': 'f00035', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0003_6', 'name': 'f00036', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0003_7', 'name': 'f00037', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0003_8', 'name': 'f00038', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0003_9', 'name': 'f00039', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_0004_1', 'name': 'f00041', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0004_2', 'name': 'f00042', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0004_3', 'name': 'f00043', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0004_4', 'name': 'f00044', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0004_5', 'name': 'f00045', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0004_6', 'name': 'f00046', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0004_7', 'name': 'f00047', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0004_8', 'name': 'f00048', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0004_9', 'name': 'f00049', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_0005_1', 'name': 'f00051', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0005_2', 'name': 'f00052', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0005_3', 'name': 'f00053', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0005_4', 'name': 'f00054', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0005_5', 'name': 'f00055', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0005_6', 'name': 'f00056', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0005_7', 'name': 'f00057', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0005_8', 'name': 'f00058', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0005_9', 'name': 'f00059', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_0006_1', 'name': 'f00061', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0006_2', 'name': 'f00062', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0006_3', 'name': 'f00063', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0006_4', 'name': 'f00064', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0006_5', 'name': 'f00065', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0006_6', 'name': 'f00066', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0006_7', 'name': 'f00067', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0006_8', 'name': 'f00068', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0006_9', 'name': 'f00069', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_0008_1', 'name': 'f00081', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0008_2', 'name': 'f00082', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0008_3', 'name': 'f00083', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0008_4', 'name': 'f00084', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0008_5', 'name': 'f00085', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0008_6', 'name': 'f00086', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0008_7', 'name': 'f00087', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0008_8', 'name': 'f00088', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0008_9', 'name': 'f00089', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_0009_1', 'name': 'f00091', 'star': 1,'family': 'TT','color': 'GR'},
	{'item': 'f_0009_2', 'name': 'f00092', 'star': 1,'family': 'TT','color': 'BL'},
	{'item': 'f_0009_3', 'name': 'f00093', 'star': 1,'family': 'TT','color': 'YE'},
	{'item': 'f_0009_4', 'name': 'f00094', 'star': 1,'family': 'TT','color': 'RE'},
	{'item': 'f_0009_5', 'name': 'f00095', 'star': 1,'family': 'TT','color': 'PU'},
	{'item': 'f_0009_6', 'name': 'f00096', 'star': 1,'family': 'TT','color': 'X1'},
	{'item': 'f_0009_7', 'name': 'f00097', 'star': 1,'family': 'TT','color': 'X2'},
	{'item': 'f_0009_8', 'name': 'f00098', 'star': 1,'family': 'TT','color': 'X3'},
	{'item': 'f_0009_9', 'name': 'f00099', 'star': 1,'family': 'TT','color': 'X4'},

	{'item': 'f_1001_1', 'name': 'f10011', 'star': 1,'family': 'XF','color': 'GR'},
	{'item': 'f_1001_2', 'name': 'f10012', 'star': 1,'family': 'XF','color': 'BL'},
	{'item': 'f_1001_3', 'name': 'f10013', 'star': 1,'family': 'XF','color': 'YE'},
	{'item': 'f_1001_4', 'name': 'f10014', 'star': 1,'family': 'XF','color': 'RE'},
	{'item': 'f_1001_5', 'name': 'f10015', 'star': 1,'family': 'XF','color': 'PU'},
	{'item': 'f_1001_6', 'name': 'f10016', 'star': 1,'family': 'XF','color': 'X1'},
	{'item': 'f_1001_7', 'name': 'f10017', 'star': 1,'family': 'XF','color': 'X2'},
	{'item': 'f_1001_8', 'name': 'f10018', 'star': 1,'family': 'XF','color': 'X3'},
	{'item': 'f_1001_9', 'name': 'f10019', 'star': 1,'family': 'XF','color': 'X4'},

	{'item': 'f_1002_1', 'name': 'f10021', 'star': 1,'family': 'XF','color': 'GR'},
	{'item': 'f_1002_2', 'name': 'f10022', 'star': 1,'family': 'XF','color': 'BL'},
	{'item': 'f_1002_3', 'name': 'f10023', 'star': 1,'family': 'XF','color': 'YE'},
	{'item': 'f_1002_4', 'name': 'f10024', 'star': 1,'family': 'XF','color': 'RE'},
	{'item': 'f_1002_5', 'name': 'f10025', 'star': 1,'family': 'XF','color': 'PU'},
	{'item': 'f_1002_6', 'name': 'f10026', 'star': 1,'family': 'XF','color': 'X1'},
	{'item': 'f_1002_7', 'name': 'f10027', 'star': 1,'family': 'XF','color': 'X2'},
	{'item': 'f_1002_8', 'name': 'f10028', 'star': 1,'family': 'XF','color': 'X3'},
	{'item': 'f_1002_9', 'name': 'f10029', 'star': 1,'family': 'XF','color': 'X4'},

	{'item': 'f_1003_1', 'name': 'f10031', 'star': 1,'family': 'XF','color': 'GR'},
	{'item': 'f_1003_2', 'name': 'f10032', 'star': 1,'family': 'XF','color': 'BL'},
	{'item': 'f_1003_3', 'name': 'f10033', 'star': 1,'family': 'XF','color': 'YE'},
	{'item': 'f_1003_4', 'name': 'f10034', 'star': 1,'family': 'XF','color': 'RE'},
	{'item': 'f_1003_5', 'name': 'f10035', 'star': 1,'family': 'XF','color': 'PU'},
	{'item': 'f_1003_6', 'name': 'f10036', 'star': 1,'family': 'XF','color': 'X1'},
	{'item': 'f_1003_7', 'name': 'f10037', 'star': 1,'family': 'XF','color': 'X2'},
	{'item': 'f_1003_8', 'name': 'f10038', 'star': 1,'family': 'XF','color': 'X3'},
	{'item': 'f_1003_9', 'name': 'f10039', 'star': 1,'family': 'XF','color': 'X4'},

	{'item': 'f_1004_1', 'name': 'f10041', 'star': 1,'family': 'XF','color': 'GR'},
	{'item': 'f_1004_2', 'name': 'f10042', 'star': 1,'family': 'XF','color': 'BL'},
	{'item': 'f_1004_3', 'name': 'f10043', 'star': 1,'family': 'XF','color': 'YE'},
	{'item': 'f_1004_4', 'name': 'f10044', 'star': 1,'family': 'XF','color': 'RE'},
	{'item': 'f_1004_5', 'name': 'f10045', 'star': 1,'family': 'XF','color': 'PU'},
	{'item': 'f_1004_6', 'name': 'f10046', 'star': 1,'family': 'XF','color': 'X1'},
	{'item': 'f_1004_7', 'name': 'f10047', 'star': 1,'family': 'XF','color': 'X2'},
	{'item': 'f_1004_8', 'name': 'f10048', 'star': 1,'family': 'XF','color': 'X3'},
	{'item': 'f_1004_9', 'name': 'f10049', 'star': 1,'family': 'XF','color': 'X4'},

	{'item': 'f_1005_1', 'name': 'f10051', 'star': 1,'family': 'XF','color': 'GR'},
	{'item': 'f_1005_2', 'name': 'f10052', 'star': 1,'family': 'XF','color': 'BL'},
	{'item': 'f_1005_3', 'name': 'f10053', 'star': 1,'family': 'XF','color': 'YE'},
	{'item': 'f_1005_4', 'name': 'f10054', 'star': 1,'family': 'XF','color': 'RE'},
	{'item': 'f_1005_5', 'name': 'f10055', 'star': 1,'family': 'XF','color': 'PU'},
	{'item': 'f_1005_6', 'name': 'f10056', 'star': 1,'family': 'XF','color': 'X1'},
	{'item': 'f_1005_7', 'name': 'f10057', 'star': 1,'family': 'XF','color': 'X2'},
	{'item': 'f_1005_8', 'name': 'f10058', 'star': 1,'family': 'XF','color': 'X3'},
	{'item': 'f_1005_9', 'name': 'f10059', 'star': 1,'family': 'XF','color': 'X4'},

	{'item': 'f_1006_1', 'name': 'f10061', 'star': 1,'family': 'XF','color': 'GR'},
	{'item': 'f_1006_2', 'name': 'f10062', 'star': 1,'family': 'XF','color': 'BL'},
	{'item': 'f_1006_3', 'name': 'f10063', 'star': 1,'family': 'XF','color': 'YE'},
	{'item': 'f_1006_4', 'name': 'f10064', 'star': 1,'family': 'XF','color': 'RE'},
	{'item': 'f_1006_5', 'name': 'f10065', 'star': 1,'family': 'XF','color': 'PU'},
	{'item': 'f_1006_6', 'name': 'f10066', 'star': 1,'family': 'XF','color': 'X1'},
	{'item': 'f_1006_7', 'name': 'f10067', 'star': 1,'family': 'XF','color': 'X2'},
	{'item': 'f_1006_8', 'name': 'f10068', 'star': 1,'family': 'XF','color': 'X3'},
	{'item': 'f_1006_9', 'name': 'f10069', 'star': 1,'family': 'XF','color': 'X4'},

	{'item': 'f_1007_1', 'name': 'f10071', 'star': 1,'family': 'XF','color': 'GR'},
	{'item': 'f_1007_2', 'name': 'f10072', 'star': 1,'family': 'XF','color': 'BL'},
	{'item': 'f_1007_3', 'name': 'f10073', 'star': 1,'family': 'XF','color': 'YE'},
	{'item': 'f_1007_4', 'name': 'f10074', 'star': 1,'family': 'XF','color': 'RE'},
	{'item': 'f_1007_5', 'name': 'f10075', 'star': 1,'family': 'XF','color': 'PU'},
	{'item': 'f_1007_6', 'name': 'f10076', 'star': 1,'family': 'XF','color': 'X1'},
	{'item': 'f_1007_7', 'name': 'f10077', 'star': 1,'family': 'XF','color': 'X2'},
	{'item': 'f_1007_8', 'name': 'f10078', 'star': 1,'family': 'XF','color': 'X3'},
	{'item': 'f_1007_9', 'name': 'f10079', 'star': 1,'family': 'XF','color': 'X4'},

	{'item': 'f_2001_1', 'name': 'f20011', 'star': 1,'family': 'SD','color': 'GR'},
	{'item': 'f_2001_2', 'name': 'f20012', 'star': 1,'family': 'SD','color': 'BL'},
	{'item': 'f_2001_3', 'name': 'f20013', 'star': 1,'family': 'SD','color': 'YE'},
	{'item': 'f_2001_4', 'name': 'f20014', 'star': 1,'family': 'SD','color': 'RE'},
	{'item': 'f_2001_5', 'name': 'f20015', 'star': 1,'family': 'SD','color': 'PU'},
	{'item': 'f_2001_6', 'name': 'f20016', 'star': 1,'family': 'SD','color': 'X1'},
	{'item': 'f_2001_7', 'name': 'f20017', 'star': 1,'family': 'SD','color': 'X2'},
	{'item': 'f_2001_8', 'name': 'f20018', 'star': 1,'family': 'SD','color': 'X3'},
	{'item': 'f_2001_9', 'name': 'f20019', 'star': 1,'family': 'SD','color': 'X4'},

	{'item': 'f_2002_1', 'name': 'f20021', 'star': 1,'family': 'SD','color': 'GR'},
	{'item': 'f_2002_2', 'name': 'f20022', 'star': 1,'family': 'SD','color': 'BL'},
	{'item': 'f_2002_3', 'name': 'f20023', 'star': 1,'family': 'SD','color': 'YE'},
	{'item': 'f_2002_4', 'name': 'f20024', 'star': 1,'family': 'SD','color': 'RE'},
	{'item': 'f_2002_5', 'name': 'f20025', 'star': 1,'family': 'SD','color': 'PU'},
	{'item': 'f_2002_6', 'name': 'f20026', 'star': 1,'family': 'SD','color': 'X1'},
	{'item': 'f_2002_7', 'name': 'f20027', 'star': 1,'family': 'SD','color': 'X2'},
	{'item': 'f_2002_8', 'name': 'f20028', 'star': 1,'family': 'SD','color': 'X3'},
	{'item': 'f_2002_9', 'name': 'f20029', 'star': 1,'family': 'SD','color': 'X4'},

	{'item': 'f_3001_1', 'name': 'f30011', 'star': 1,'family': 'DH','color': 'GR'},
	{'item': 'f_3001_2', 'name': 'f30012', 'star': 1,'family': 'DH','color': 'BL'},
	{'item': 'f_3001_3', 'name': 'f30013', 'star': 1,'family': 'DH','color': 'YE'},
	{'item': 'f_3001_4', 'name': 'f30014', 'star': 1,'family': 'DH','color': 'RE'},
	{'item': 'f_3001_5', 'name': 'f30015', 'star': 1,'family': 'DH','color': 'PU'},
	{'item': 'f_3001_6', 'name': 'f30016', 'star': 1,'family': 'DH','color': 'X1'},
	{'item': 'f_3001_7', 'name': 'f30017', 'star': 1,'family': 'DH','color': 'X2'},
	{'item': 'f_3001_8', 'name': 'f30018', 'star': 1,'family': 'DH','color': 'X3'},
	{'item': 'f_3001_9', 'name': 'f30019', 'star': 1,'family': 'DH','color': 'X4'},

	{'item': 'f_3002_1', 'name': 'f30021', 'star': 1,'family': 'DH','color': 'GR'},
	{'item': 'f_3002_2', 'name': 'f30022', 'star': 1,'family': 'DH','color': 'BL'},
	{'item': 'f_3002_3', 'name': 'f30023', 'star': 1,'family': 'DH','color': 'YE'},
	{'item': 'f_3002_4', 'name': 'f30024', 'star': 1,'family': 'DH','color': 'RE'},
	{'item': 'f_3002_5', 'name': 'f30025', 'star': 1,'family': 'DH','color': 'PU'},
	{'item': 'f_3002_6', 'name': 'f30026', 'star': 1,'family': 'DH','color': 'X1'},
	{'item': 'f_3002_7', 'name': 'f30027', 'star': 1,'family': 'DH','color': 'X2'},
	{'item': 'f_3002_8', 'name': 'f30028', 'star': 1,'family': 'DH','color': 'X3'},
	{'item': 'f_3002_9', 'name': 'f30029', 'star': 1,'family': 'DH','color': 'X4'},

	{'item': 'f_3004_1', 'name': 'f30041', 'star': 1,'family': 'DH','color': 'GR'},
	{'item': 'f_3004_2', 'name': 'f30042', 'star': 1,'family': 'DH','color': 'BL'},
	{'item': 'f_3004_3', 'name': 'f30043', 'star': 1,'family': 'DH','color': 'YE'},
	{'item': 'f_3004_4', 'name': 'f30044', 'star': 1,'family': 'DH','color': 'RE'},
	{'item': 'f_3004_5', 'name': 'f30045', 'star': 1,'family': 'DH','color': 'PU'},
	{'item': 'f_3004_6', 'name': 'f30046', 'star': 1,'family': 'DH','color': 'X1'},
	{'item': 'f_3004_7', 'name': 'f30047', 'star': 1,'family': 'DH','color': 'X2'},
	{'item': 'f_3004_8', 'name': 'f30048', 'star': 1,'family': 'DH','color': 'X3'},
	{'item': 'f_3004_9', 'name': 'f30049', 'star': 1,'family': 'DH','color': 'X4'},

	{'item': 'f_3006_1', 'name': 'f30061', 'star': 1,'family': 'DH','color': 'GR'},
	{'item': 'f_3006_2', 'name': 'f30062', 'star': 1,'family': 'DH','color': 'BL'},
	{'item': 'f_3006_3', 'name': 'f30063', 'star': 1,'family': 'DH','color': 'YE'},
	{'item': 'f_3006_4', 'name': 'f30064', 'star': 1,'family': 'DH','color': 'RE'},
	{'item': 'f_3006_5', 'name': 'f30065', 'star': 1,'family': 'DH','color': 'PU'},
	{'item': 'f_3006_6', 'name': 'f30066', 'star': 1,'family': 'DH','color': 'X1'},
	{'item': 'f_3006_7', 'name': 'f30067', 'star': 1,'family': 'DH','color': 'X2'},
	{'item': 'f_3006_8', 'name': 'f30068', 'star': 1,'family': 'DH','color': 'X3'},
	{'item': 'f_3006_9', 'name': 'f30069', 'star': 1,'family': 'DH','color': 'X4'},

	{'item': 'f_4002_1', 'name': 'f40021', 'star': 1,'family': 'JJ','color': 'GR'},
	{'item': 'f_4002_2', 'name': 'f40022', 'star': 1,'family': 'JJ','color': 'BL'},
	{'item': 'f_4002_3', 'name': 'f40023', 'star': 1,'family': 'JJ','color': 'YE'},
	{'item': 'f_4002_4', 'name': 'f40024', 'star': 1,'family': 'JJ','color': 'RE'},
	{'item': 'f_4002_5', 'name': 'f40025', 'star': 1,'family': 'JJ','color': 'PU'},
	{'item': 'f_4002_6', 'name': 'f40026', 'star': 1,'family': 'JJ','color': 'X1'},
	{'item': 'f_4002_7', 'name': 'f40027', 'star': 1,'family': 'JJ','color': 'X2'},
	{'item': 'f_4002_8', 'name': 'f40028', 'star': 1,'family': 'JJ','color': 'X3'},
	{'item': 'f_4002_9', 'name': 'f40029', 'star': 1,'family': 'JJ','color': 'X4'},

	{'item': 'f_4003_1', 'name': 'f40031', 'star': 1,'family': 'JJ','color': 'GR'},
	{'item': 'f_4003_2', 'name': 'f40032', 'star': 1,'family': 'JJ','color': 'BL'},
	{'item': 'f_4003_3', 'name': 'f40033', 'star': 1,'family': 'JJ','color': 'YE'},
	{'item': 'f_4003_4', 'name': 'f40034', 'star': 1,'family': 'JJ','color': 'RE'},
	{'item': 'f_4003_5', 'name': 'f40035', 'star': 1,'family': 'JJ','color': 'PU'},
	{'item': 'f_4003_6', 'name': 'f40036', 'star': 1,'family': 'JJ','color': 'X1'},
	{'item': 'f_4003_7', 'name': 'f40037', 'star': 1,'family': 'JJ','color': 'X2'},
	{'item': 'f_4003_8', 'name': 'f40038', 'star': 1,'family': 'JJ','color': 'X3'},
	{'item': 'f_4003_9', 'name': 'f40039', 'star': 1,'family': 'JJ','color': 'X4'},

	{'item': 'f_4005_1', 'name': 'f40051', 'star': 1,'family': 'JJ','color': 'GR'},
	{'item': 'f_4005_2', 'name': 'f40052', 'star': 1,'family': 'JJ','color': 'BL'},
	{'item': 'f_4005_3', 'name': 'f40053', 'star': 1,'family': 'JJ','color': 'YE'},
	{'item': 'f_4005_4', 'name': 'f40054', 'star': 1,'family': 'JJ','color': 'RE'},
	{'item': 'f_4005_5', 'name': 'f40055', 'star': 1,'family': 'JJ','color': 'PU'},
	{'item': 'f_4005_6', 'name': 'f40056', 'star': 1,'family': 'JJ','color': 'X1'},
	{'item': 'f_4005_7', 'name': 'f40057', 'star': 1,'family': 'JJ','color': 'X2'},
	{'item': 'f_4005_8', 'name': 'f40058', 'star': 1,'family': 'JJ','color': 'X3'},
	{'item': 'f_4005_9', 'name': 'f40059', 'star': 1,'family': 'JJ','color': 'X4'},


	{'item': 'f_5001_1', 'name': 'f50011', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5001_2', 'name': 'f50012', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5001_3', 'name': 'f50013', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5001_4', 'name': 'f50014', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5001_5', 'name': 'f50015', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5001_6', 'name': 'f50016', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5001_7', 'name': 'f50017', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5001_8', 'name': 'f50018', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5001_9', 'name': 'f50019', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_5002_1', 'name': 'f50021', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5002_2', 'name': 'f50022', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5002_3', 'name': 'f50023', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5002_4', 'name': 'f50024', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5002_5', 'name': 'f50025', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5002_6', 'name': 'f50026', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5002_7', 'name': 'f50027', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5002_8', 'name': 'f50028', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5002_9', 'name': 'f50029', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_5003_1', 'name': 'f50031', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5003_2', 'name': 'f50032', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5003_3', 'name': 'f50033', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5003_4', 'name': 'f50034', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5003_5', 'name': 'f50035', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5003_6', 'name': 'f50036', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5003_7', 'name': 'f50037', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5003_8', 'name': 'f50038', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5003_9', 'name': 'f50039', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_5004_1', 'name': 'f50041', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5004_2', 'name': 'f50042', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5004_3', 'name': 'f50043', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5004_4', 'name': 'f50044', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5004_5', 'name': 'f50045', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5004_6', 'name': 'f50046', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5004_7', 'name': 'f50047', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5004_8', 'name': 'f50048', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5004_9', 'name': 'f50049', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_5005_1', 'name': 'f50051', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5005_2', 'name': 'f50052', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5005_3', 'name': 'f50053', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5005_4', 'name': 'f50054', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5005_5', 'name': 'f50055', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5005_6', 'name': 'f50056', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5005_7', 'name': 'f50057', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5005_8', 'name': 'f50058', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5005_9', 'name': 'f50059', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_5006_1', 'name': 'f50061', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5006_2', 'name': 'f50062', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5006_3', 'name': 'f50063', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5006_4', 'name': 'f50064', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5006_5', 'name': 'f50065', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5006_6', 'name': 'f50066', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5006_7', 'name': 'f50067', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5006_8', 'name': 'f50068', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5006_9', 'name': 'f50069', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_5007_1', 'name': 'f50071', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5007_2', 'name': 'f50072', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5007_3', 'name': 'f50073', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5007_4', 'name': 'f50074', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5007_5', 'name': 'f50075', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5007_6', 'name': 'f50076', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5007_7', 'name': 'f50077', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5007_8', 'name': 'f50078', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5007_9', 'name': 'f50079', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_5012_1', 'name': 'f50121', 'star': 1,'family': 'BM','color': 'GR'},
	{'item': 'f_5012_2', 'name': 'f50122', 'star': 1,'family': 'BM','color': 'BL'},
	{'item': 'f_5012_3', 'name': 'f50123', 'star': 1,'family': 'BM','color': 'YE'},
	{'item': 'f_5012_4', 'name': 'f50124', 'star': 1,'family': 'BM','color': 'RE'},
	{'item': 'f_5012_5', 'name': 'f50125', 'star': 1,'family': 'BM','color': 'PU'},
	{'item': 'f_5012_6', 'name': 'f50126', 'star': 1,'family': 'BM','color': 'X1'},
	{'item': 'f_5012_7', 'name': 'f50127', 'star': 1,'family': 'BM','color': 'X2'},
	{'item': 'f_5012_8', 'name': 'f50128', 'star': 1,'family': 'BM','color': 'X3'},
	{'item': 'f_5012_9', 'name': 'f50129', 'star': 1,'family': 'BM','color': 'X4'},

	{'item': 'f_4014_1', 'name': 'f40141', 'star': 1,'family': 'JJ','color': 'GR'},
	{'item': 'f_4014_2', 'name': 'f40142', 'star': 1,'family': 'JJ','color': 'BL'},
	{'item': 'f_4014_3', 'name': 'f40143', 'star': 1,'family': 'JJ','color': 'YE'},
	{'item': 'f_4014_4', 'name': 'f40144', 'star': 1,'family': 'JJ','color': 'RE'},
	{'item': 'f_4014_5', 'name': 'f40145', 'star': 1,'family': 'JJ','color': 'PU'},
	{'item': 'f_4014_6', 'name': 'f40146', 'star': 1,'family': 'JJ','color': 'X1'},
	{'item': 'f_4014_7', 'name': 'f40147', 'star': 1,'family': 'JJ','color': 'X2'},
	{'item': 'f_4014_8', 'name': 'f40148', 'star': 1,'family': 'JJ','color': 'X3'},
	{'item': 'f_4014_9', 'name': 'f40149', 'star': 1,'family': 'JJ','color': 'X4'},	

	{'item': 'f_1010_1', 'name': 'f10101', 'star': 2,'family': 'XF','color': 'GR'},
	{'item': 'f_1010_2', 'name': 'f10102', 'star': 2,'family': 'XF','color': 'BL'},
	{'item': 'f_1010_3', 'name': 'f10103', 'star': 2,'family': 'XF','color': 'YE'},
	{'item': 'f_1010_4', 'name': 'f10104', 'star': 2,'family': 'XF','color': 'RE'},
	{'item': 'f_1010_5', 'name': 'f10105', 'star': 2,'family': 'XF','color': 'PU'},
	{'item': 'f_1010_6', 'name': 'f10106', 'star': 2,'family': 'XF','color': 'X1'},
	{'item': 'f_1010_7', 'name': 'f10107', 'star': 2,'family': 'XF','color': 'X2'},
	{'item': 'f_1010_8', 'name': 'f10108', 'star': 2,'family': 'XF','color': 'X3'},
	{'item': 'f_1010_9', 'name': 'f10109', 'star': 2,'family': 'XF','color': 'X4'},

	{'item': 'f_2003_1', 'name': 'f20031', 'star': 2,'family': 'SD','color': 'GR'},
	{'item': 'f_2003_2', 'name': 'f20032', 'star': 2,'family': 'SD','color': 'BL'},
	{'item': 'f_2003_3', 'name': 'f20033', 'star': 2,'family': 'SD','color': 'YE'},
	{'item': 'f_2003_4', 'name': 'f20034', 'star': 2,'family': 'SD','color': 'RE'},
	{'item': 'f_2003_5', 'name': 'f20035', 'star': 2,'family': 'SD','color': 'PU'},
	{'item': 'f_2003_6', 'name': 'f20036', 'star': 2,'family': 'SD','color': 'X1'},
	{'item': 'f_2003_7', 'name': 'f20037', 'star': 2,'family': 'SD','color': 'X2'},
	{'item': 'f_2003_8', 'name': 'f20038', 'star': 2,'family': 'SD','color': 'X3'},
	{'item': 'f_2003_9', 'name': 'f20039', 'star': 2,'family': 'SD','color': 'X4'},

	{'item': 'f_2008_1', 'name': 'f20081', 'star': 2,'family': 'SD','color': 'GR'},
	{'item': 'f_2008_2', 'name': 'f20082', 'star': 2,'family': 'SD','color': 'BL'},
	{'item': 'f_2008_3', 'name': 'f20083', 'star': 2,'family': 'SD','color': 'YE'},
	{'item': 'f_2008_4', 'name': 'f20084', 'star': 2,'family': 'SD','color': 'RE'},
	{'item': 'f_2008_5', 'name': 'f20085', 'star': 2,'family': 'SD','color': 'PU'},
	{'item': 'f_2008_6', 'name': 'f20086', 'star': 2,'family': 'SD','color': 'X1'},
	{'item': 'f_2008_7', 'name': 'f20087', 'star': 2,'family': 'SD','color': 'X2'},
	{'item': 'f_2008_8', 'name': 'f20088', 'star': 2,'family': 'SD','color': 'X3'},
	{'item': 'f_2008_9', 'name': 'f20089', 'star': 2,'family': 'SD','color': 'X4'},

	{'item': 'f_3003_1', 'name': 'f30031', 'star': 2,'family': 'DH','color': 'GR'},
	{'item': 'f_3003_2', 'name': 'f30032', 'star': 2,'family': 'DH','color': 'BL'},
	{'item': 'f_3003_3', 'name': 'f30033', 'star': 2,'family': 'DH','color': 'YE'},
	{'item': 'f_3003_4', 'name': 'f30034', 'star': 2,'family': 'DH','color': 'RE'},
	{'item': 'f_3003_5', 'name': 'f30035', 'star': 2,'family': 'DH','color': 'PU'},
	{'item': 'f_3003_6', 'name': 'f30036', 'star': 2,'family': 'DH','color': 'X1'},
	{'item': 'f_3003_7', 'name': 'f30037', 'star': 2,'family': 'DH','color': 'X2'},
	{'item': 'f_3003_8', 'name': 'f30038', 'star': 2,'family': 'DH','color': 'X3'},
	{'item': 'f_3003_9', 'name': 'f30039', 'star': 2,'family': 'DH','color': 'X4'},

	{'item': 'f_3005_1', 'name': 'f30051', 'star': 2,'family': 'DH','color': 'GR'},
	{'item': 'f_3005_2', 'name': 'f30052', 'star': 2,'family': 'DH','color': 'BL'},
	{'item': 'f_3005_3', 'name': 'f30053', 'star': 2,'family': 'DH','color': 'YE'},
	{'item': 'f_3005_4', 'name': 'f30054', 'star': 2,'family': 'DH','color': 'RE'},
	{'item': 'f_3005_5', 'name': 'f30055', 'star': 2,'family': 'DH','color': 'PU'},
	{'item': 'f_3005_6', 'name': 'f30056', 'star': 2,'family': 'DH','color': 'X1'},
	{'item': 'f_3005_7', 'name': 'f30057', 'star': 2,'family': 'DH','color': 'X2'},
	{'item': 'f_3005_8', 'name': 'f30058', 'star': 2,'family': 'DH','color': 'X3'},
	{'item': 'f_3005_9', 'name': 'f30059', 'star': 2,'family': 'DH','color': 'X4'},

	{'item': 'f_4004_1', 'name': 'f40041', 'star': 2,'family': 'JJ','color': 'GR'},
	{'item': 'f_4004_2', 'name': 'f40042', 'star': 2,'family': 'JJ','color': 'BL'},
	{'item': 'f_4004_3', 'name': 'f40043', 'star': 2,'family': 'JJ','color': 'YE'},
	{'item': 'f_4004_4', 'name': 'f40044', 'star': 2,'family': 'JJ','color': 'RE'},
	{'item': 'f_4004_5', 'name': 'f40045', 'star': 2,'family': 'JJ','color': 'PU'},
	{'item': 'f_4004_6', 'name': 'f40046', 'star': 2,'family': 'JJ','color': 'X1'},
	{'item': 'f_4004_7', 'name': 'f40047', 'star': 2,'family': 'JJ','color': 'X2'},
	{'item': 'f_4004_8', 'name': 'f40048', 'star': 2,'family': 'JJ','color': 'X3'},
	{'item': 'f_4004_9', 'name': 'f40049', 'star': 2,'family': 'JJ','color': 'X4'},

	{'item': 'f_5008_1', 'name': 'f50081', 'star': 2,'family': 'BM','color': 'GR'},
	{'item': 'f_5008_2', 'name': 'f50082', 'star': 2,'family': 'BM','color': 'BL'},
	{'item': 'f_5008_3', 'name': 'f50083', 'star': 2,'family': 'BM','color': 'YE'},
	{'item': 'f_5008_4', 'name': 'f50084', 'star': 2,'family': 'BM','color': 'RE'},
	{'item': 'f_5008_5', 'name': 'f50085', 'star': 2,'family': 'BM','color': 'PU'},
	{'item': 'f_5008_6', 'name': 'f50086', 'star': 2,'family': 'BM','color': 'X1'},
	{'item': 'f_5008_7', 'name': 'f50087', 'star': 2,'family': 'BM','color': 'X2'},
	{'item': 'f_5008_8', 'name': 'f50088', 'star': 2,'family': 'BM','color': 'X3'},
	{'item': 'f_5008_9', 'name': 'f50089', 'star': 2,'family': 'BM','color': 'X4'},

	{'item': 'f_3009_1', 'name': 'f30091', 'star': 2,'family': 'DH','color': 'GR'},
	{'item': 'f_3009_2', 'name': 'f30092', 'star': 2,'family': 'DH','color': 'BL'},
	{'item': 'f_3009_3', 'name': 'f30093', 'star': 2,'family': 'DH','color': 'YE'},
	{'item': 'f_3009_4', 'name': 'f30094', 'star': 2,'family': 'DH','color': 'RE'},
	{'item': 'f_3009_5', 'name': 'f30095', 'star': 2,'family': 'DH','color': 'PU'},
	{'item': 'f_3009_6', 'name': 'f30096', 'star': 2,'family': 'DH','color': 'X1'},
	{'item': 'f_3009_7', 'name': 'f30097', 'star': 2,'family': 'DH','color': 'X2'},
	{'item': 'f_3009_8', 'name': 'f30098', 'star': 2,'family': 'DH','color': 'X3'},
	{'item': 'f_3009_9', 'name': 'f30099', 'star': 2,'family': 'DH','color': 'X4'},

	{'item': 'f_4008_1', 'name': 'f40081', 'star': 2,'family': 'JJ','color': 'GR'},
	{'item': 'f_4008_2', 'name': 'f40082', 'star': 2,'family': 'JJ','color': 'BL'},
	{'item': 'f_4008_3', 'name': 'f40083', 'star': 2,'family': 'JJ','color': 'YE'},
	{'item': 'f_4008_4', 'name': 'f40084', 'star': 2,'family': 'JJ','color': 'RE'},
	{'item': 'f_4008_5', 'name': 'f40085', 'star': 2,'family': 'JJ','color': 'PU'},
	{'item': 'f_4008_6', 'name': 'f40086', 'star': 2,'family': 'JJ','color': 'X1'},
	{'item': 'f_4008_7', 'name': 'f40087', 'star': 2,'family': 'JJ','color': 'X2'},
	{'item': 'f_4008_8', 'name': 'f40088', 'star': 2,'family': 'JJ','color': 'X3'},
	{'item': 'f_4008_9', 'name': 'f40089', 'star': 2,'family': 'JJ','color': 'X4'},

	{'item': 'f_0011_1', 'name': 'f00111', 'star': 2,'family': 'TT','color': 'GR'},
	{'item': 'f_0011_2', 'name': 'f00112', 'star': 2,'family': 'TT','color': 'BL'},
	{'item': 'f_0011_3', 'name': 'f00113', 'star': 2,'family': 'TT','color': 'YE'},
	{'item': 'f_0011_4', 'name': 'f00114', 'star': 2,'family': 'TT','color': 'RE'},
	{'item': 'f_0011_5', 'name': 'f00115', 'star': 2,'family': 'TT','color': 'PU'},
	{'item': 'f_0011_6', 'name': 'f00116', 'star': 2,'family': 'TT','color': 'X1'},
	{'item': 'f_0011_7', 'name': 'f00117', 'star': 2,'family': 'TT','color': 'X2'},
	{'item': 'f_0011_8', 'name': 'f00118', 'star': 2,'family': 'TT','color': 'X3'},
	{'item': 'f_0011_9', 'name': 'f00119', 'star': 2,'family': 'TT','color': 'X4'},

	{'item': 'f_2009_1', 'name': 'f20091', 'star': 2,'family': 'SD','color': 'GR'},
	{'item': 'f_2009_2', 'name': 'f20092', 'star': 2,'family': 'SD','color': 'BL'},
	{'item': 'f_2009_3', 'name': 'f20093', 'star': 2,'family': 'SD','color': 'YE'},
	{'item': 'f_2009_4', 'name': 'f20094', 'star': 2,'family': 'SD','color': 'RE'},
	{'item': 'f_2009_5', 'name': 'f20095', 'star': 2,'family': 'SD','color': 'PU'},
	{'item': 'f_2009_6', 'name': 'f20096', 'star': 2,'family': 'SD','color': 'X1'},
	{'item': 'f_2009_7', 'name': 'f20097', 'star': 2,'family': 'SD','color': 'X2'},
	{'item': 'f_2009_8', 'name': 'f20098', 'star': 2,'family': 'SD','color': 'X3'},
	{'item': 'f_2009_9', 'name': 'f20099', 'star': 2,'family': 'SD','color': 'X4'},

	{'item': 'f_1011_1', 'name': 'f10111', 'star': 2,'family': 'XF','color': 'GR'},
	{'item': 'f_1011_2', 'name': 'f10112', 'star': 2,'family': 'XF','color': 'BL'},
	{'item': 'f_1011_3', 'name': 'f10113', 'star': 2,'family': 'XF','color': 'YE'},
	{'item': 'f_1011_4', 'name': 'f10114', 'star': 2,'family': 'XF','color': 'RE'},
	{'item': 'f_1011_5', 'name': 'f10115', 'star': 2,'family': 'XF','color': 'PU'},
	{'item': 'f_1011_6', 'name': 'f10116', 'star': 2,'family': 'XF','color': 'X1'},
	{'item': 'f_1011_7', 'name': 'f10117', 'star': 2,'family': 'XF','color': 'X2'},
	{'item': 'f_1011_8', 'name': 'f10118', 'star': 2,'family': 'XF','color': 'X3'},
	{'item': 'f_1011_9', 'name': 'f10119', 'star': 2,'family': 'XF','color': 'X4'},	

	{'item': 'f_0015_1', 'name': 'f00151', 'star': 2,'family': 'TT','color': 'GR'},
	{'item': 'f_0015_2', 'name': 'f00152', 'star': 2,'family': 'TT','color': 'BL'},
	{'item': 'f_0015_3', 'name': 'f00153', 'star': 2,'family': 'TT','color': 'YE'},
	{'item': 'f_0015_4', 'name': 'f00154', 'star': 2,'family': 'TT','color': 'RE'},
	{'item': 'f_0015_5', 'name': 'f00155', 'star': 2,'family': 'TT','color': 'PU'},
	{'item': 'f_0015_6', 'name': 'f00156', 'star': 2,'family': 'TT','color': 'X1'},
	{'item': 'f_0015_7', 'name': 'f00157', 'star': 2,'family': 'TT','color': 'X2'},
	{'item': 'f_0015_8', 'name': 'f00158', 'star': 2,'family': 'TT','color': 'X3'},
	{'item': 'f_0015_9', 'name': 'f00159', 'star': 2,'family': 'TT','color': 'X4'},

	{'item': 'f_0010_1', 'name': 'f00101', 'star': 3,'family': 'TT','color': 'GR'},
	{'item': 'f_0010_2', 'name': 'f00102', 'star': 3,'family': 'TT','color': 'BL'},
	{'item': 'f_0010_3', 'name': 'f00103', 'star': 3,'family': 'TT','color': 'YE'},
	{'item': 'f_0010_4', 'name': 'f00104', 'star': 3,'family': 'TT','color': 'RE'},
	{'item': 'f_0010_5', 'name': 'f00105', 'star': 3,'family': 'TT','color': 'PU'},
	{'item': 'f_0010_6', 'name': 'f00106', 'star': 3,'family': 'TT','color': 'X1'},
	{'item': 'f_0010_7', 'name': 'f00107', 'star': 3,'family': 'TT','color': 'X2'},
	{'item': 'f_0010_8', 'name': 'f00108', 'star': 3,'family': 'TT','color': 'X3'},
	{'item': 'f_0010_9', 'name': 'f00109', 'star': 3,'family': 'TT','color': 'X4'},

	{'item': 'f_2004_1', 'name': 'f20041', 'star': 3,'family': 'SD','color': 'GR'},
	{'item': 'f_2004_2', 'name': 'f20042', 'star': 3,'family': 'SD','color': 'BL'},
	{'item': 'f_2004_3', 'name': 'f20043', 'star': 3,'family': 'SD','color': 'YE'},
	{'item': 'f_2004_4', 'name': 'f20044', 'star': 3,'family': 'SD','color': 'RE'},
	{'item': 'f_2004_5', 'name': 'f20045', 'star': 3,'family': 'SD','color': 'PU'},
	{'item': 'f_2004_6', 'name': 'f20046', 'star': 3,'family': 'SD','color': 'X1'},
	{'item': 'f_2004_7', 'name': 'f20047', 'star': 3,'family': 'SD','color': 'X2'},
	{'item': 'f_2004_8', 'name': 'f20048', 'star': 3,'family': 'SD','color': 'X3'},
	{'item': 'f_2004_9', 'name': 'f20049', 'star': 3,'family': 'SD','color': 'X4'},

	{'item': 'f_3008_1', 'name': 'f30081', 'star': 3,'family': 'DH','color': 'GR'},
	{'item': 'f_3008_2', 'name': 'f30082', 'star': 3,'family': 'DH','color': 'BL'},
	{'item': 'f_3008_3', 'name': 'f30083', 'star': 3,'family': 'DH','color': 'YE'},
	{'item': 'f_3008_4', 'name': 'f30084', 'star': 3,'family': 'DH','color': 'RE'},
	{'item': 'f_3008_5', 'name': 'f30085', 'star': 3,'family': 'DH','color': 'PU'},
	{'item': 'f_3008_6', 'name': 'f30086', 'star': 3,'family': 'DH','color': 'X1'},
	{'item': 'f_3008_7', 'name': 'f30087', 'star': 3,'family': 'DH','color': 'X2'},
	{'item': 'f_3008_8', 'name': 'f30088', 'star': 3,'family': 'DH','color': 'X3'},
	{'item': 'f_3008_9', 'name': 'f30089', 'star': 3,'family': 'DH','color': 'X4'},

	{'item': 'f_4001_1', 'name': 'f40011', 'star': 3,'family': 'JJ','color': 'GR'},
	{'item': 'f_4001_2', 'name': 'f40012', 'star': 3,'family': 'JJ','color': 'BL'},
	{'item': 'f_4001_3', 'name': 'f40013', 'star': 3,'family': 'JJ','color': 'YE'},
	{'item': 'f_4001_4', 'name': 'f40014', 'star': 3,'family': 'JJ','color': 'RE'},
	{'item': 'f_4001_5', 'name': 'f40015', 'star': 3,'family': 'JJ','color': 'PU'},
	{'item': 'f_4001_6', 'name': 'f40016', 'star': 3,'family': 'JJ','color': 'X1'},
	{'item': 'f_4001_7', 'name': 'f40017', 'star': 3,'family': 'JJ','color': 'X2'},
	{'item': 'f_4001_8', 'name': 'f40018', 'star': 3,'family': 'JJ','color': 'X3'},
	{'item': 'f_4001_9', 'name': 'f40019', 'star': 3,'family': 'JJ','color': 'X4'},

	{'item': 'f_5011_1', 'name': 'f50111', 'star': 3,'family': 'BM','color': 'GR'},
	{'item': 'f_5011_2', 'name': 'f50112', 'star': 3,'family': 'BM','color': 'BL'},
	{'item': 'f_5011_3', 'name': 'f50113', 'star': 3,'family': 'BM','color': 'YE'},
	{'item': 'f_5011_4', 'name': 'f50114', 'star': 3,'family': 'BM','color': 'RE'},
	{'item': 'f_5011_5', 'name': 'f50115', 'star': 3,'family': 'BM','color': 'PU'},
	{'item': 'f_5011_6', 'name': 'f50116', 'star': 3,'family': 'BM','color': 'X1'},
	{'item': 'f_5011_7', 'name': 'f50117', 'star': 3,'family': 'BM','color': 'X2'},
	{'item': 'f_5011_8', 'name': 'f50118', 'star': 3,'family': 'BM','color': 'X3'},
	{'item': 'f_5011_9', 'name': 'f50119', 'star': 3,'family': 'BM','color': 'X4'},

	{'item': 'f_4009_1', 'name': 'f40091', 'star': 3,'family': 'JJ','color': 'GR'},
	{'item': 'f_4009_2', 'name': 'f40092', 'star': 3,'family': 'JJ','color': 'BL'},
	{'item': 'f_4009_3', 'name': 'f40093', 'star': 3,'family': 'JJ','color': 'YE'},
	{'item': 'f_4009_4', 'name': 'f40094', 'star': 3,'family': 'JJ','color': 'RE'},
	{'item': 'f_4009_5', 'name': 'f40095', 'star': 3,'family': 'JJ','color': 'PU'},
	{'item': 'f_4009_6', 'name': 'f40096', 'star': 3,'family': 'JJ','color': 'X1'},
	{'item': 'f_4009_7', 'name': 'f40097', 'star': 3,'family': 'JJ','color': 'X2'},
	{'item': 'f_4009_8', 'name': 'f40098', 'star': 3,'family': 'JJ','color': 'X3'},
	{'item': 'f_4009_9', 'name': 'f40099', 'star': 3,'family': 'JJ','color': 'X4'},

	{'item': 'f_3010_1', 'name': 'f30101', 'star': 3,'family': 'DH','color': 'GR'},
	{'item': 'f_3010_2', 'name': 'f30102', 'star': 3,'family': 'DH','color': 'BL'},
	{'item': 'f_3010_3', 'name': 'f30103', 'star': 3,'family': 'DH','color': 'YE'},
	{'item': 'f_3010_4', 'name': 'f30104', 'star': 3,'family': 'DH','color': 'RE'},
	{'item': 'f_3010_5', 'name': 'f30105', 'star': 3,'family': 'DH','color': 'PU'},
	{'item': 'f_3010_6', 'name': 'f30106', 'star': 3,'family': 'DH','color': 'X1'},
	{'item': 'f_3010_7', 'name': 'f30107', 'star': 3,'family': 'DH','color': 'X2'},
	{'item': 'f_3010_8', 'name': 'f30108', 'star': 3,'family': 'DH','color': 'X3'},
	{'item': 'f_3010_9', 'name': 'f30109', 'star': 3,'family': 'DH','color': 'X4'},

	{'item': 'f_0012_1', 'name': 'f00121', 'star': 3,'family': 'TT','color': 'GR'},
	{'item': 'f_0012_2', 'name': 'f00122', 'star': 3,'family': 'TT','color': 'BL'},
	{'item': 'f_0012_3', 'name': 'f00123', 'star': 3,'family': 'TT','color': 'YE'},
	{'item': 'f_0012_4', 'name': 'f00124', 'star': 3,'family': 'TT','color': 'RE'},
	{'item': 'f_0012_5', 'name': 'f00125', 'star': 3,'family': 'TT','color': 'PU'},
	{'item': 'f_0012_6', 'name': 'f00126', 'star': 3,'family': 'TT','color': 'X1'},
	{'item': 'f_0012_7', 'name': 'f00127', 'star': 3,'family': 'TT','color': 'X2'},
	{'item': 'f_0012_8', 'name': 'f00128', 'star': 3,'family': 'TT','color': 'X3'},
	{'item': 'f_0012_9', 'name': 'f00129', 'star': 3,'family': 'TT','color': 'X4'},

	{'item': 'f_4010_1', 'name': 'f40101', 'star': 3,'family': 'JJ','color': 'GR'},
	{'item': 'f_4010_2', 'name': 'f40102', 'star': 3,'family': 'JJ','color': 'BL'},
	{'item': 'f_4010_3', 'name': 'f40103', 'star': 3,'family': 'JJ','color': 'YE'},
	{'item': 'f_4010_4', 'name': 'f40104', 'star': 3,'family': 'JJ','color': 'RE'},
	{'item': 'f_4010_5', 'name': 'f40105', 'star': 3,'family': 'JJ','color': 'PU'},
	{'item': 'f_4010_6', 'name': 'f40106', 'star': 3,'family': 'JJ','color': 'X1'},
	{'item': 'f_4010_7', 'name': 'f40107', 'star': 3,'family': 'JJ','color': 'X2'},
	{'item': 'f_4010_8', 'name': 'f40108', 'star': 3,'family': 'JJ','color': 'X3'},
	{'item': 'f_4010_9', 'name': 'f40109', 'star': 3,'family': 'JJ','color': 'X4'},

	{'item': 'f_4012_1', 'name': 'f40121', 'star': 3,'family': 'JJ','color': 'GR'},
	{'item': 'f_4012_2', 'name': 'f40122', 'star': 3,'family': 'JJ','color': 'BL'},
	{'item': 'f_4012_3', 'name': 'f40123', 'star': 3,'family': 'JJ','color': 'YE'},
	{'item': 'f_4012_4', 'name': 'f40124', 'star': 3,'family': 'JJ','color': 'RE'},
	{'item': 'f_4012_5', 'name': 'f40125', 'star': 3,'family': 'JJ','color': 'PU'},
	{'item': 'f_4012_6', 'name': 'f40126', 'star': 3,'family': 'JJ','color': 'X1'},
	{'item': 'f_4012_7', 'name': 'f40127', 'star': 3,'family': 'JJ','color': 'X2'},
	{'item': 'f_4012_8', 'name': 'f40128', 'star': 3,'family': 'JJ','color': 'X3'},
	{'item': 'f_4012_9', 'name': 'f40129', 'star': 3,'family': 'JJ','color': 'X4'},

	{'item': 'f_5013_1', 'name': 'f50131', 'star': 3,'family': 'BM','color': 'GR'},
	{'item': 'f_5013_2', 'name': 'f50132', 'star': 3,'family': 'BM','color': 'BL'},
	{'item': 'f_5013_3', 'name': 'f50133', 'star': 3,'family': 'BM','color': 'YE'},
	{'item': 'f_5013_4', 'name': 'f50134', 'star': 3,'family': 'BM','color': 'RE'},
	{'item': 'f_5013_5', 'name': 'f50135', 'star': 3,'family': 'BM','color': 'PU'},
	{'item': 'f_5013_6', 'name': 'f50136', 'star': 3,'family': 'BM','color': 'X1'},
	{'item': 'f_5013_7', 'name': 'f50137', 'star': 3,'family': 'BM','color': 'X2'},
	{'item': 'f_5013_8', 'name': 'f50138', 'star': 3,'family': 'BM','color': 'X3'},
	{'item': 'f_5013_9', 'name': 'f50139', 'star': 3,'family': 'BM','color': 'X4'},

	{'item': 'f_0013_1', 'name': 'f00131', 'star': 3,'family': 'TT','color': 'GR'},
	{'item': 'f_0013_2', 'name': 'f00132', 'star': 3,'family': 'TT','color': 'BL'},
	{'item': 'f_0013_3', 'name': 'f00133', 'star': 3,'family': 'TT','color': 'YE'},
	{'item': 'f_0013_4', 'name': 'f00134', 'star': 3,'family': 'TT','color': 'RE'},
	{'item': 'f_0013_5', 'name': 'f00135', 'star': 3,'family': 'TT','color': 'PU'},
	{'item': 'f_0013_6', 'name': 'f00136', 'star': 3,'family': 'TT','color': 'X1'},
	{'item': 'f_0013_7', 'name': 'f00137', 'star': 3,'family': 'TT','color': 'X2'},
	{'item': 'f_0013_8', 'name': 'f00138', 'star': 3,'family': 'TT','color': 'X3'},
	{'item': 'f_0013_9', 'name': 'f00139', 'star': 3,'family': 'TT','color': 'X4'},

	{'item': 'f_4013_1', 'name': 'f40131', 'star': 3,'family': 'JJ','color': 'GR'},
	{'item': 'f_4013_2', 'name': 'f40132', 'star': 3,'family': 'JJ','color': 'BL'},
	{'item': 'f_4013_3', 'name': 'f40133', 'star': 3,'family': 'JJ','color': 'YE'},
	{'item': 'f_4013_4', 'name': 'f40134', 'star': 3,'family': 'JJ','color': 'RE'},
	{'item': 'f_4013_5', 'name': 'f40135', 'star': 3,'family': 'JJ','color': 'PU'},
	{'item': 'f_4013_6', 'name': 'f40136', 'star': 3,'family': 'JJ','color': 'X1'},
	{'item': 'f_4013_7', 'name': 'f40137', 'star': 3,'family': 'JJ','color': 'X2'},
	{'item': 'f_4013_8', 'name': 'f40138', 'star': 3,'family': 'JJ','color': 'X3'},
	{'item': 'f_4013_9', 'name': 'f40139', 'star': 3,'family': 'JJ','color': 'X4'},

	{'item': 'f_2010_1', 'name': 'f20101', 'star': 3,'family': 'SD','color': 'GR'},
	{'item': 'f_2010_2', 'name': 'f20102', 'star': 3,'family': 'SD','color': 'BL'},
	{'item': 'f_2010_3', 'name': 'f20103', 'star': 3,'family': 'SD','color': 'YE'},
	{'item': 'f_2010_4', 'name': 'f20104', 'star': 3,'family': 'SD','color': 'RE'},
	{'item': 'f_2010_5', 'name': 'f20105', 'star': 3,'family': 'SD','color': 'PU'},
	{'item': 'f_2010_6', 'name': 'f20106', 'star': 3,'family': 'SD','color': 'X1'},
	{'item': 'f_2010_7', 'name': 'f20107', 'star': 3,'family': 'SD','color': 'X2'},
	{'item': 'f_2010_8', 'name': 'f20108', 'star': 3,'family': 'SD','color': 'X3'},
	{'item': 'f_2010_9', 'name': 'f20109', 'star': 3,'family': 'SD','color': 'X4'},

	{'item': 'f_0014_1', 'name': 'f00141', 'star': 3,'family': 'TT','color': 'GR'},
	{'item': 'f_0014_2', 'name': 'f00142', 'star': 3,'family': 'TT','color': 'BL'},
	{'item': 'f_0014_3', 'name': 'f00143', 'star': 3,'family': 'TT','color': 'YE'},
	{'item': 'f_0014_4', 'name': 'f00144', 'star': 3,'family': 'TT','color': 'RE'},
	{'item': 'f_0014_5', 'name': 'f00145', 'star': 3,'family': 'TT','color': 'PU'},
	{'item': 'f_0014_6', 'name': 'f00146', 'star': 3,'family': 'TT','color': 'X1'},
	{'item': 'f_0014_7', 'name': 'f00147', 'star': 3,'family': 'TT','color': 'X2'},
	{'item': 'f_0014_8', 'name': 'f00148', 'star': 3,'family': 'TT','color': 'X3'},
	{'item': 'f_0014_9', 'name': 'f00149', 'star': 3,'family': 'TT','color': 'X4'},

	{'item': 'f_1012_1', 'name': 'f10121', 'star': 3,'family': 'XF','color': 'GR'},
	{'item': 'f_1012_2', 'name': 'f10122', 'star': 3,'family': 'XF','color': 'BL'},
	{'item': 'f_1012_3', 'name': 'f10123', 'star': 3,'family': 'XF','color': 'YE'},
	{'item': 'f_1012_4', 'name': 'f10124', 'star': 3,'family': 'XF','color': 'RE'},
	{'item': 'f_1012_5', 'name': 'f10125', 'star': 3,'family': 'XF','color': 'PU'},
	{'item': 'f_1012_6', 'name': 'f10126', 'star': 3,'family': 'XF','color': 'X1'},
	{'item': 'f_1012_7', 'name': 'f10127', 'star': 3,'family': 'XF','color': 'X2'},
	{'item': 'f_1012_8', 'name': 'f10128', 'star': 3,'family': 'XF','color': 'X3'},
	{'item': 'f_1012_9', 'name': 'f10129', 'star': 3,'family': 'XF','color': 'X4'},

	{'item': 'f_3011_1', 'name': 'f30111', 'star': 4,'family': 'DH','color': 'GR'},
	{'item': 'f_3011_2', 'name': 'f30112', 'star': 4,'family': 'DH','color': 'BL'},
	{'item': 'f_3011_3', 'name': 'f30113', 'star': 4,'family': 'DH','color': 'YE'},
	{'item': 'f_3011_4', 'name': 'f30114', 'star': 4,'family': 'DH','color': 'RE'},
	{'item': 'f_3011_5', 'name': 'f30115', 'star': 4,'family': 'DH','color': 'PU'},
	{'item': 'f_3011_6', 'name': 'f30116', 'star': 4,'family': 'DH','color': 'X1'},
	{'item': 'f_3011_7', 'name': 'f30117', 'star': 4,'family': 'DH','color': 'X2'},
	{'item': 'f_3011_8', 'name': 'f30118', 'star': 4,'family': 'DH','color': 'X3'},
	{'item': 'f_3011_9', 'name': 'f30119', 'star': 4,'family': 'DH','color': 'X4'},

	{'item': 'f_3012_1', 'name': 'f30121', 'star': 4,'family': 'DH','color': 'GR'},
	{'item': 'f_3012_2', 'name': 'f30122', 'star': 4,'family': 'DH','color': 'BL'},
	{'item': 'f_3012_3', 'name': 'f30123', 'star': 4,'family': 'DH','color': 'YE'},
	{'item': 'f_3012_4', 'name': 'f30124', 'star': 4,'family': 'DH','color': 'RE'},
	{'item': 'f_3012_5', 'name': 'f30125', 'star': 4,'family': 'DH','color': 'PU'},
	{'item': 'f_3012_6', 'name': 'f30126', 'star': 4,'family': 'DH','color': 'X1'},
	{'item': 'f_3012_7', 'name': 'f30127', 'star': 4,'family': 'DH','color': 'X2'},
	{'item': 'f_3012_8', 'name': 'f30128', 'star': 4,'family': 'DH','color': 'X3'},
	{'item': 'f_3012_9', 'name': 'f30129', 'star': 4,'family': 'DH','color': 'X4'}
]
export { tankFilterSource, propFilterSource, fishFilterSource }