import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SetToken, IsLogin } from '../utils';

import '../css/login.css';
import { NetLogin } from '../http';


function Login() {
    let navigate = useNavigate();

    useEffect(() => {
        if (IsLogin()) {
            navigate('/');
        }

    })
    const btnClick = () => {
        const email = document.getElementById('email').value;
        const pwd = document.getElementById('password').value;
        if (email === '' || pwd === '') {
            // alert('请输入完整信息');
            return;
        }
        NetLogin(email, pwd)
            .then(res => {
                if (res.data.code === 0) {
                    SetToken(res.data.data.token);
                    navigate('/');
                    return;
                } else {
                    alert(res.data.msg);
                }
                return;
            })
            .catch(e => {
                console.log(e);
            }
        );
        return;
    }

    return (
        <div className='haha'>
            <div className="container">
                <h1>Please Login</h1>
                    <div className="form-control">
                        <input type="text" id="email" required />
                        <label>
                            <span style={{transitionDelay: 0+'ms'}}>E</span>
                            <span style={{transitionDelay: 50+'ms'}}>m</span>
                            <span style={{transitionDelay: 100+'ms'}}>a</span>
                            <span style={{transitionDelay: 150+'ms'}}>i</span>
                            <span style={{transitionDelay: 200+'ms'}}>l</span>
                        </label>
                    </div>

                    <div className="form-control">
                        <input type="password" id="password" required />
                        <label>
                            <span style={{transitionDelay: 0+'ms'}}>P</span>
                            <span style={{transitionDelay: 50+'ms'}}>a</span>
                            <span style={{transitionDelay: 100+'ms'}}>s</span>
                            <span style={{transitionDelay: 150+'ms'}}>s</span>
                            <span style={{transitionDelay: 200+'ms'}}>w</span>
                            <span style={{transitionDelay: 250+'ms'}}>o</span>
                            <span style={{transitionDelay: 300+'ms'}}>r</span>
                            <span style={{transitionDelay: 350+'ms'}}>d</span>
                        </label>
                    </div>

                    <button className="btn" onClick={btnClick}>Login</button>
            </div>
        </div>

    );
};

export default Login;